import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55d891e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "organization" }
const _hoisted_2 = { class: "organization-container" }
const _hoisted_3 = { class: "top-header" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "help-link" }
const _hoisted_7 = { class: "message" }
const _hoisted_8 = {
  href: "https://help.digitalsign.jp/hc/ja/articles/4406942274329",
  target: "_blank"
}
const _hoisted_9 = { class: "message" }
const _hoisted_10 = {
  href: "https://help.digitalsign.jp/hc/ja/articles/5054635472153",
  target: "_blank"
}
const _hoisted_11 = { class: "organization-content" }
const _hoisted_12 = { class: "content-value" }
const _hoisted_13 = { class: "input-label" }
const _hoisted_14 = { class: "avatar" }
const _hoisted_15 = { class: "button-upload" }
const _hoisted_16 = { class: "content-value mt-4" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "mt-4" }
const _hoisted_19 = { class: "address content-value" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "address-input" }
const _hoisted_22 = { class: "province-input mt-2" }
const _hoisted_23 = { class: "city-line mt-2" }
const _hoisted_24 = { class: "department mt-2" }
const _hoisted_25 = { class: "content-value mt-4" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "content-value mt-4" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "city-line" }
const _hoisted_30 = {
  class: "content-value mt-5",
  style: {"align-items":"flex-end"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("onboarding.organizationTaglineText")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("onboarding.organizationTitleText")), 1),
        _createVNode(_component_Notification, {
          class: _normalizeClass(["notification-desk", { 'notification-mob': _ctx.isMobile }])
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkOrganizationFirst')), 1),
          _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('onboarding.helpLinkHere')), 1),
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkLast')), 1)
        ]),
        _createElementVNode("p", _hoisted_9, [
          _createTextVNode(" （" + _toDisplayString(_ctx.$t('onboarding.helpLinkPersonalFirst')), 1),
          _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('onboarding.helpLinkHere')), 1),
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkLast')) + "） ", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("onboarding.organizationLogo")), 1),
          _createElementVNode("div", {
            class: "logo-upload",
            onDragleave: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
            onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_AvatarCircle, {
                avatar: 
                  _ctx.imageRenderer == ''
                    ? require('icons/default-company-avatar.svg')
                    : _ctx.imageRenderer
                ,
                class: "prefix-icon"
              }, null, 8, ["avatar"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("input", {
                id: "imageUploader",
                type: "file",
                style: {"display":"none"},
                ref: "file",
                accept: "image/png, image/jpeg",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)))
              }, null, 544),
              _createVNode(_component_TextButton, {
                enabled: true,
                onOnClick: _ctx.uploadFile,
                icon: require('icons/camera.svg'),
                text: _ctx.$t('onboarding.logoText')
              }, null, 8, ["onOnClick", "icon", "text"])
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", {
            innerHTML: 
              `<span style='color: #D64D10'>${_ctx.$t(
                'common.requiredText'
              )}</span>` + _ctx.$t('onboarding.organizationName')
            ,
            for: "name",
            class: "input-label"
          }, null, 8, _hoisted_17),
          _createVNode(_component_ValidationTextField, {
            value: _ctx.name,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.name) = $event)),
            class: "text-field",
            placeholder: _ctx.$t('onboarding.organizationNameHint'),
            "keep-error-space": false,
            rules: "required",
            message: {
              error_required: _ctx.$t('errors.required'),
            },
            name: "name",
            onOnBlur: _ctx.checkExistCompanyName
          }, null, 8, ["value", "placeholder", "message", "onOnBlur"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("label", {
              innerHTML: 
                `<span style='color: #D64D10'>${_ctx.$t(
                  'common.requiredText'
                )}</span>` + _ctx.$t('onboarding.organizationAddress')
              ,
              for: "organizationAddress",
              class: "input-label"
            }, null, 8, _hoisted_20),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_ValidationTextField, {
                value: _ctx.postalCode,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.postalCode) = $event)),
                type: "text",
                class: "address-field mr-1",
                placeholder: _ctx.$t('onboarding.organizationAddressHint'),
                "keep-error-space": false,
                name: "postalCode",
                rules: "postalCode|required",
                message: {
                  error_required: _ctx.$t('errors.required'),
                  error_format_postal_code: _ctx.$t('errors.invalidPostalCode'),
                },
                onOnBlur: _ctx.validatePostalCode
              }, null, 8, ["value", "placeholder", "message", "onOnBlur"]),
              _createVNode(_component_FlatButton, {
                class: "address-button",
                enabled: true,
                text: _ctx.$t('onboarding.organizationAutofillAddress'),
                onOnClick: _ctx.autoFillAddress
              }, null, 8, ["text", "onOnClick"])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_Dropdown, {
                onOnChange: _ctx.selectProvince,
                options: _ctx.provinces,
                "default-option": _ctx.province?.id,
                "place-holder": _ctx.$t('onboarding.organizationProvinceDropdown'),
                rules: "required",
                name: "province"
              }, null, 8, ["onOnChange", "options", "default-option", "place-holder"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_ValidationTextField, {
                value: _ctx.city,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.city) = $event)),
                type: "text",
                class: "text-field mr-1",
                placeholder: _ctx.$t('onboarding.organizationCityHint'),
                "keep-error-space": false,
                name: "city",
                rules: "required",
                message: {
                  error_required: _ctx.$t('errors.required'),
                }
              }, null, 8, ["value", "placeholder", "message"]),
              _createVNode(_component_ValidationTextField, {
                value: _ctx.address,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.address) = $event)),
                type: "text",
                class: "text-field",
                placeholder: _ctx.$t('onboarding.organizationStreetHint'),
                "keep-error-space": false,
                name: "address",
                rules: "required",
                message: {
                  error_required: _ctx.$t('errors.required'),
                }
              }, null, 8, ["value", "placeholder", "message"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_ValidationTextField, {
                value: _ctx.buildingRoom,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.buildingRoom) = $event)),
                type: "text",
                class: "text-field",
                placeholder: _ctx.$t('onboarding.organizationDepartmentHint'),
                "keep-error-space": false,
                name: "buildingRoom"
              }, null, 8, ["value", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("label", {
            innerHTML: 
              `<span style='color: #D64D10'>${_ctx.$t(
                'common.requiredText'
              )}</span>` + _ctx.$t('onboarding.organizationPhone')
            ,
            for: "organizationPhone",
            class: "input-label"
          }, null, 8, _hoisted_26),
          _createVNode(_component_ValidationTextField, {
            value: _ctx.phoneNumber,
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.phoneNumber) = $event)),
            type: "text",
            class: "text-field",
            placeholder: _ctx.$t('onboarding.phoneHint'),
            "keep-error-space": false,
            name: "phoneNumber",
            "max-length": 20,
            rules: "number|required",
            message: {
              error_required: _ctx.$t('errors.required'),
              error_format_number: _ctx.$t('errors.formatNumber'),
            }
          }, null, 8, ["value", "placeholder", "message"])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("label", {
            innerHTML: 
              `<span style='color: #D64D10'>${_ctx.$t(
                'common.requiredText'
              )}</span>` + _ctx.$t('onboarding.organizationProxyName')
            ,
            for: "organizationProxyName",
            class: "input-label"
          }, null, 8, _hoisted_28),
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_ValidationTextField, {
              value: _ctx.representativeLastName,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.representativeLastName) = $event)),
              type: "text",
              class: "text-field mr-1",
              placeholder: _ctx.$t('onboarding.lastNameHint'),
              "keep-error-space": false,
              name: "representativeLastName",
              rules: "name|required",
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
            }, null, 8, ["value", "placeholder", "message"]),
            _createVNode(_component_ValidationTextField, {
              value: _ctx.representativeFirstName,
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.representativeFirstName) = $event)),
              type: "text",
              class: "text-field",
              placeholder: _ctx.$t('onboarding.firstNameHint'),
              "keep-error-space": false,
              name: "representativeFirstName",
              rules: "name|required",
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              }
            }, null, 8, ["value", "placeholder", "message"])
          ])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_TextButton, {
            text: _ctx.$t('onboarding.submitText'),
            "right-icon": require('icons/next-arrow.svg'),
            class: "organization-button",
            enabled: _ctx.enableNextButton && _ctx.isFullRequireField,
            "disabled-icon": require('icons/next-arrow-grey.svg'),
            onOnClick: _ctx.submit
          }, null, 8, ["text", "right-icon", "enabled", "disabled-icon", "onOnClick"])
        ])
      ])
    ])
  ]))
}