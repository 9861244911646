
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Notification from "@/components/atomics/Notification.vue";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  toRefs,
  watch,
  computed,
  inject,
} from "vue";
import {
  CompanyRepository,
  OnboardingRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useForm } from "vee-validate";
import router from "@/router";
import { isEmptyObject, resizeImage } from "@/lib/utility/common";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

interface Province {
  id: string;
  name: string;
}

export default defineComponent({
  name: "Organization",
  components: {
    FlatButton,
    TextButton,
    ValidationTextField,
    Dropdown,
    AvatarCircle,
    Notification,
  },
  setup() {
    const isMobile = inject("isMobile");
    const provinces = ref([] as Province[]);
    const preferences = ref([] as any[]);

    const logoFile = ref(null);
    const imageRenderer = ref("");
    const province = ref({} as Province | undefined);
    const referralCompany = ref();

    const file = ref(null);
    const uploadFile = () => {
      (file.value as any)?.click();
    };

    const formState = reactive({
      name: "",
      postalCode: "",
      city: "",
      address: "",
      buildingRoom: "",
      phoneNumber: "",
      representativeLastName: "",
      representativeFirstName: "",
      referralPersonLastName: "",
      referralPersonFirstName: "",
      controlNumber: "",

      enableNextButton: false,
      companyId: "",
    });

    const {
      getProvinces,
      getPreferences,
      createCompany,
      getCompany,
      getAddressByZip,
      checkExistName,
    } = organizationMethods();

    const goNext = () => {
      router.push({ name: "Profile" });
    };

    const { values, errors, submitForm, setFieldError } = useForm();
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    watch(
      () => errors.value,
      (val) => {
        formState.enableNextButton = isEmptyObject(val);
      }
    );

    const checkExistCompanyName = async () => {
      if (formState.name.trim() != "") {
        const isExist = await checkExistName(formState.name);
        if (isExist != "ok") setFieldError("name", t("errors.existName"));
      }
    };

    const isFullRequireField = computed(() => {
      const {
        name,
        postalCode,
        city,
        address,
        phoneNumber,
        representativeLastName,
        representativeFirstName,
      } = formState;

      return (
        name != "" &&
        postalCode != "" &&
        city != "" &&
        address != "" &&
        phoneNumber != "" &&
        representativeLastName != "" &&
        representativeFirstName != "" &&
        (province.value?.id || false) &&
        (referralCompany.value?.id || false)
      );
    });
    const submit = () => {
      submitForm().then(async () => {
        if (!isEmptyObject(errors.value)) return;
        // const formData = createFormData();
        const formData = new FormData();

        if (logoFile.value) formData.append("logo", logoFile.value);

        if (formState.companyId) formData.append("id", formState.companyId);

        formData.append("name", values["name"] ?? "");
        formData.append("postalCode", values["postalCode"] ?? "");
        formData.append("prefectureId", (province.value as any).id ?? "");
        formData.append("city", values["city"] ?? "");
        formData.append("address", values["address"] ?? "");
        formData.append("buildingRoom", values["buildingRoom"] ?? "");
        formData.append("phoneNumber", values["phoneNumber"] ?? "");
        formData.append(
          "representativeLastName",
          values["representativeLastName"] ?? ""
        );
        formData.append(
          "representativeFirstName",
          values["representativeFirstName"] ?? ""
        );
        formData.append("referralCompanyId", referralCompany.value.id);
        formData.append(
          "referralPersonLastName",
          values["referralPersonLastName"] ?? ""
        );
        formData.append(
          "referralPersonFirstName",
          values["referralPersonFirstName"] ?? ""
        );
        formData.append("controlNumber", values["controlNumber"] ?? "");

        try {
          if (await createCompany(formData)) {
            goNext();
          }
        } catch (e: any) {
          if (e.data) {
            store.commit(
              "notification/showErrorNotification",
              e.data.message || ""
            );
          }
        }
      });
    };

    onMounted(async () => {
      provinces.value = await getProvinces();
      preferences.value = await getPreferences();


      try {
        const company = await getCompany();
        if (company) {
          imageRenderer.value = company.logo
            ? process.env.VUE_APP_API_CLOUD_URL +
              company.logo +
              `?${Date.now()}`
            : "";
          formState.name = company.name;
          formState.postalCode = company.postalCode;
          formState.city = company.city;
          formState.address = company.address;
          formState.buildingRoom = company.buildingRoom;
          formState.phoneNumber = company.phoneNumber;
          formState.representativeLastName = company.representativeLastName;
          formState.representativeFirstName = company.representativeFirstName;
          formState.referralPersonLastName = company.referralPersonLastName;
          formState.referralPersonFirstName = company.referralPersonFirstName;
          formState.controlNumber = company.controlNumber;
          province.value = provinces.value.find(
            (item) => item.id === company.prefectureId
          );
          referralCompany.value = preferences.value.find(
            (item: any) => item.id === company.referralCompanyId
          );

          formState.companyId = company.id;
        } else {
          // province.value = provinces.value[1];
          if (route.query.partner === "1")
            referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社Wiz")];
          else if (route.query.partner === "2")
            referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社C-mind")];
          else referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社デジタルサイン")];
          formState.companyId = "";
        }

      } catch (e) {
        // province.value = provinces.value[1];
          if (route.query.partner === "1")
            referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社Wiz")];
          else if (route.query.partner === "2")
            referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社C-mind")];
          else referralCompany.value = preferences.value[preferences.value.findIndex((c) => c.name === "株式会社デジタルサイン")];
        formState.companyId = "";
      }
    });

    const autoFillAddress = async () => {
      const address = await getAddressByZip(formState.postalCode);
      if (address) {
        if (address.code == 200) {
          const { pref, city, town } = address.data;
          province.value = provinces.value.find((item) => item.name == pref);
          formState.city = city;
          formState.address = town;
        } else {
          setFieldError("postalCode", t("errors.invalidPostalCode"));
        }
      }
    };
    const validatePostalCode = async () => {
      const address = await getAddressByZip(formState.postalCode);
      if ((address as any).code != 200)
        setFieldError("postalCode", t("errors.invalidPostalCode"));
    };

    const uploadImage = async (e: any) => {
      const sourceFile = e?.target.files || e.dataTransfer.files;
      const file = sourceFile[0];
      if (file.size > 200000) {
        store.commit(
          "notification/showErrorNotification",
          t("errors.errorOversizeAvatar")
        );
        return;
      }
      const imageResized = (await resizeImage(file)) as any;

      imageRenderer.value = imageResized.base64;
      logoFile.value = imageResized.fileUpload;
    };

    return {
      file,
      provinces,
      submit,
      errors,
      logoFile,
      imageRenderer,
      province,
      referralCompany,
      autoFillAddress,
      uploadFile,
      isFullRequireField,
      resizeImage,
      validatePostalCode,
      checkExistCompanyName,
      uploadImage,
      ...toRefs(formState),
      isMobile,
    };
  },
  methods: {
    selectProvince(provinceId: string) {
      this.province = this.provinces.find((item) => item.id == provinceId);
    },
  },
});

const organizationMethods = () => {
  const { getProvinces, getPreferences, getAddressByZip } =
    RepositoryFactory.getRepository<OnboardingRepository>(OnboardingRepository);

  const { getCompany, createCompany, checkExistName } =
    RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

  return {
    getProvinces,
    getAddressByZip,
    getPreferences,
    getCompany,
    createCompany,
    checkExistName,
  };
};
